export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'image',
    label: 'field.image',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'name',
    label: 'field.name',
    hideToggle: true,
  },
  {
    key: 'newsCategory',
    label: 'field.newsCategory',
    hideToggle: true,
  },
  {
    key: 'type',
    label: 'field.type',
    hideToggle: true,
  },
  {
    key: 'description',
    label: 'field.description',
  },
  {
    key: 'status',
    label: 'field.status',
    hideToggle: true,
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'id',
    label: 'field.createdDate',
    hideToggle: true,
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updatedDate',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
