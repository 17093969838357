export default [
  {
    key: 'newsCategoryId',
    label: 'field.category',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'newsCategory',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    type: 'text',
    operator: '%',
  },
  {
    key: 'isEnable',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.active', value: '1' },
      { text: 'field.inactive', value: '0' },
    ],
  },
]
